import Typography from '@mui/material/Typography'
import ReactMarkdown from 'react-markdown'

const Body1HeaderFn = (props: any) => <Typography variant="body3" component="p" {...props} />

const Markdown: React.FC<{ children: string }> = ({ children }) => {
    return (
        <ReactMarkdown
            components={{
                p: Body1HeaderFn,
                h1: ({ node: _node, ...props }) => <Typography variant="h6" {...props} />,
                h2: ({ node: _node, ...props }) => (
                    <Typography
                        variant="body3"
                        component="p"
                        sx={{ fontWeight: 'bold' }}
                        {...props}
                    />
                ),
                h3: Body1HeaderFn,
                h4: Body1HeaderFn,
                h5: Body1HeaderFn,
                h6: Body1HeaderFn,
            }}
        >
            {children}
        </ReactMarkdown>
    )
}

export default Markdown
