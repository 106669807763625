import type { SxProps } from '@mui/material'
import Grid from '@mui/material/Grid'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'

export interface ApiReferenceSectionProps {
    children: React.ReactNode[]
    sx?: SxProps
}

const ApiReferenceSection = ({ children, sx }: ApiReferenceSectionProps): JSX.Element => {
    if (children.length !== 2) {
        throw new Error(`ApiReferenceSection accepts two children only, given ${children.length}`)
    }

    const leftColumnRef = useRef<HTMLDivElement>(null)
    const [leftColumnHeight, setLeftColumnHeight] = useState<number | null>(null)

    useEffect(() => {
        const updateHeight = () => {
            if (leftColumnRef.current) {
                setLeftColumnHeight(leftColumnRef.current.offsetHeight)
            }
        }

        // Initial height calculation
        updateHeight()

        // Recalculate height on window resize
        window.addEventListener('resize', updateHeight)
        return () => window.removeEventListener('resize', updateHeight)
    }, [])

    return (
        <Grid
            container
            spacing={3}
            sx={{
                mt: 4,
                mb: 4,
                ...sx,
                '.MuiTypography-h5:first-of-type': {
                    mt: 0,
                },
                display: 'flex',
                alignItems: 'stretch',
                justifyContent: 'flex-start',
                height: 'unset',
                flexWrap: {
                    xs: 'wrap',
                    md: 'nowrap',
                },
            }}
        >
            <Grid ref={leftColumnRef} item xs={12} sm={12} md={6} lg={6} xl={6}>
                {children[0]}
            </Grid>
            <Grid
                item
                sx={{
                    position: 'sticky',
                    height: 'max-content',
                    top: '50px',
                    '.code-snippet': {
                        maxHeight: {
                            xs: 'auto',
                            md: leftColumnHeight ? `${leftColumnHeight - 52}px` : 'none',
                        },
                        mb: 0,
                    },
                }}
                xs={12}
                sm={12}
                md={6}
                lg={6}
                xl={6}
            >
                {children[1]}
            </Grid>
        </Grid>
    )
}

export default ApiReferenceSection
