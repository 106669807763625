import type { SxProps } from '@mui/material'
import Box from '@mui/material/Box'
import MUIDivider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/system'
import React, { FC, useMemo } from 'react'

import { LuneTheme } from '../theme'

import ExpandableList from './ExpandableList'
import Markdown from './Markdown'
import MutuallyExclusiveList, { MutuallyExclusiveListOption } from './MutuallyExclusiveList'

type BaseJsonProperty = {
    name: string
    required?: boolean
    nullable?: boolean
    description?: string
}

export type SimpleJsonProperty = BaseJsonProperty & {
    type: 'string' | 'number' | 'float' | 'boolean' | 'integer'
    format?: string
    pattern?: string
    $enum?: readonly (string | number)[]
}

export type ArrayJsonProperty = BaseJsonProperty & {
    type: 'array'
    $ref?: string
    jsons: readonly AnyJsonProperty[]
}

export type ObjectJsonProperty = BaseJsonProperty & {
    type: 'object'
    $ref?: string
    jsons: readonly AnyJsonProperty[]
}

export type AllOfProperty = BaseJsonProperty & {
    type: 'allOf'
    jsons: readonly AnyJsonProperty[]
}

export type OneOfProperty = BaseJsonProperty & {
    type: 'oneOf'
    defaultName?: string
    jsons: readonly AnyJsonProperty[]
}

export type AnyJsonProperty =
    | SimpleJsonProperty
    | ArrayJsonProperty
    | ObjectJsonProperty
    | AllOfProperty
    | OneOfProperty

type JsonPropertyProps = {
    level?: number
    hasSiblings?: boolean
    json: AnyJsonProperty
    sx?: SxProps
}

type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] }

type Type =
    | SimpleJsonProperty['type']
    | ArrayJsonProperty['type']
    | ObjectJsonProperty['type']
    | AllOfProperty['type']
    | OneOfProperty['type']

const isSimpleJsonPropery = (json: AnyJsonProperty): boolean => {
    return ['string', 'number', 'float', 'boolean', 'integer'].includes(json.type)
}

const displayName = (type: Type, level: number, hasSiblings: boolean | undefined): boolean => {
    if (type === 'oneOf' && level === 0) {
        return false
    }

    // if hasSiblings is undefined, we displayName
    if (type === 'array' && level === 0 && hasSiblings !== true) {
        return false
    }

    return true
}

// SimpleJsonProperty, ArrayJsonProperty and ObjectJsonProperty ensure json's props have the correct types.
// Eg, an array has to have type=array and must have a jsons props.
// For rendering though, we don't need to know that, therefore it's is fine to convert properties that are not in common to undefined.
const extractProps = (
    type: Type,
    json: AnyJsonProperty,
): Partial<
    Pick<SimpleJsonProperty, '$enum' | 'format' | 'pattern'> & {
        jsons?: readonly AnyJsonProperty[]
        // eslint-disable-next-line @typescript-eslint/no-duplicate-type-constituents
        $ref?: ArrayJsonProperty['$ref'] | ObjectJsonProperty['$ref']
    }
> => {
    if (isSimpleJsonPropery(json)) {
        return { ...(json as SimpleJsonProperty), jsons: undefined, $ref: undefined }
    }

    if (type === 'array') {
        return {
            ...(json as ArrayJsonProperty),
            $enum: undefined,
            format: undefined,
            pattern: undefined,
        }
    }

    if (type === 'object') {
        return {
            ...(json as ObjectJsonProperty),
            $enum: undefined,
            format: undefined,
            pattern: undefined,
        }
    }

    if (type === 'allOf') {
        return {
            ...(json as AllOfProperty),
            $enum: undefined,
            format: undefined,
            pattern: undefined,
        }
    }

    if (type === 'oneOf') {
        return {
            ...(json as OneOfProperty),
            $enum: undefined,
            format: undefined,
            pattern: undefined,
        }
    }

    throw new Error('this should not occur')
}

const formatType = (json: AnyJsonProperty): string => {
    const { type } = json

    if (type === 'allOf') {
        return 'object'
    }

    if (type === 'oneOf') {
        return ''
    }

    if (type === 'array') {
        const jsons = json.jsons
        let childType = (jsons[0] as SimpleJsonProperty).$enum ? 'enum' : jsons[0].type
        childType = childType === 'allOf' ? 'object' : childType
        return jsons.length === 1 ? `array of ${childType}` : `array`
    }

    if ((json as SimpleJsonProperty).$enum) {
        return 'enum'
    }

    return type
}

const hasSingleObjectAsChild = (json: ObjectJsonProperty): boolean => {
    return (
        json.jsons.length === 1 && json.jsons[0].type === 'object' && json.jsons[0].jsons.length > 0
    )
}

export const expandAllOf = (
    property: { jsons: readonly AnyJsonProperty[] },
    level: number, // level in the tree
): JsonPropertyProps[] => {
    // This function 'expands' all children. This means it pushes children of children one level up.
    return property.jsons.reduce((acc, json) => {
        // if a child is an oneOf, do not push its children, which are mutually exclusive, one level up
        if (json.type === 'oneOf') {
            return [...acc, { level, json }]
        }

        if (json.type === 'allOf') {
            return [...acc, ...expandAllOf(json, level)]
        }

        const childArray = ('jsons' in json ? json.jsons : []).map((json2) => {
            return { level: level + 1, json: json2 }
        })

        return [...acc, ...childArray]
    }, [] as JsonPropertyProps[])
}

const Divider = () => {
    const { palette } = LuneTheme
    return (
        <MUIDivider
            sx={{ width: '100%', borderColor: palette.Grey300, color: palette.Grey300 }}
            orientation="horizontal"
            flexItem
        />
    )
}

/* eslint-disable @typescript-eslint/no-use-before-define */
const JsonProperty: FC<JsonPropertyProps> = (props) => {
    const { json } = props
    const { type } = json
    const { jsons } = extractProps(type, json)
    // level starts at 0 with root JsonProperty properties (there can be
    // multiple).
    // Then increments by one for every child.
    // level is relative to how JsonProperty are rendered, not their place in
    // the OpenAPI spec.
    // level can be used to apply special logic: eg, if an JsonProperty is at
    // the root do a, b, c.
    const level = props.level ?? 0

    const wrapper = (): React.ReactElement<any, any> => {
        if (level === 0 && type === 'allOf') {
            return (
                <>
                    {expandAllOf(json, level).map((elem, i, arr) => {
                        return (
                            <React.Fragment key={i}>
                                <JsonProperty {...elem} hasSiblings />
                                {i < arr.length - 1 && <Divider />}
                            </React.Fragment>
                        )
                    })}
                </>
            )
        } else if (type === 'object' && jsons!.length > 0 && level === 0 && !props.hasSiblings) {
            return (
                <>
                    {jsons!.map((json, i) => (
                        <>
                            <JsonProperty key={i} json={json} level={level} hasSiblings />
                            {i < jsons!.length - 1 && <Divider />}
                        </>
                    ))}
                </>
            )
        } else if (type === 'allOf' && jsons!.length === 1) {
            // merge parent and child
            const newProps = {
                ...props,
                json: {
                    ...props.json,
                    ...jsons![0],
                    name: props.json.name,
                    // parent description has precedence over the child's
                    description: props.json.description ?? jsons![0].description,
                },
            }
            return <JsonPropertyActual {...newProps} level={level} />
        } else {
            return <JsonPropertyActual {...props} level={level} />
        }
    }

    return wrapper()
}

// eslint-disable-next-line complexity
const JsonPropertyActual: FC<WithRequired<JsonPropertyProps, 'level'>> = ({
    json,
    level,
    hasSiblings,
    sx,
    ...rest
}) => {
    const { typography, palette, spacing } = LuneTheme

    const { type, required, nullable, description } = json
    const { $enum, format, pattern, jsons, $ref } = extractProps(type, json)

    const name = type === 'object' && hasSingleObjectAsChild(json) ? json.jsons[0].name : json.name

    const StyledJsonProperty = useMemo(
        () =>
            styled(Stack)(
                LuneTheme.unstable_sx({
                    ...typography.body3,
                    color: palette.Grey600,
                    '&.Box-root': {
                        padding: '0px',
                    },
                    '& ul': {
                        listStylePosition: 'inside',
                        padding: `0px 0px 0px ${spacing(1)}`,
                        '& li': {
                            padding: `${spacing(0.5)} 0px`,
                        },
                    },
                    '& .MuiTypography-root[variant="body1"]': {
                        ...typography.body1,
                        color: palette.Grey600,
                    },
                    '& .MuiTypography-root[variant="body3"]': {
                        ...typography.body3,
                        color: palette.Grey600,
                    },
                    '& .MuiTypography-root[variant="button"]': {
                        ...typography.button,
                    },
                }),
            ),
        [],
    )

    const expandObject = (json: ObjectJsonProperty, level: number) => {
        return json.jsons.map((json1, j) => <JsonProperty level={level + 1} key={j} json={json1} />)
    }

    const expandArrayFirstChildRef = (
        property: { jsons: readonly AnyJsonProperty[] },
        level: number,
    ) => {
        if (jsons!.length !== 1) {
            throw new Error(`${jsons} must have length 1`)
        }
        const firstChild = jsons![0]
        if (firstChild.type === 'object') {
            return firstChild.jsons.map((json, i) => (
                <JsonProperty level={level + 1} key={i} json={json} />
            ))
        }

        return [<JsonProperty level={level + 1} key={0} json={firstChild} />]
    }

    return (
        <StyledJsonProperty spacing={2} sx={sx} {...rest}>
            {name && displayName(type, level, hasSiblings) && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-end',
                        gap: spacing(0.5),
                    }}
                >
                    {name && displayName(type, level, hasSiblings) && (
                        <Typography
                            data-testid="name"
                            variant="button"
                            sx={{ ...typography.button, color: palette.Grey900 }}
                            color="Grey900"
                        >
                            {name}
                        </Typography>
                    )}
                    {type !== 'oneOf' && (
                        <Typography data-testid="type" variant="body3" color="Grey600">
                            {formatType(json)}
                        </Typography>
                    )}
                    {!['allOf', 'oneOf'].includes(type) && format && (
                        <Typography variant="body3" color="Grey600">
                            {format}
                        </Typography>
                    )}
                    {!['allOf', 'oneOf'].includes(type) && pattern && (
                        <Typography data-testid="pattern" variant="body3" color="Grey600">
                            {pattern}
                        </Typography>
                    )}
                    {nullable === true && required === true && (
                        <Typography
                            data-testid="required-and-nullable"
                            variant="body3"
                            sx={{ color: palette.Red500 }}
                            color="Red500"
                        >
                            required, nullable
                        </Typography>
                    )}
                    {required === true && !nullable && (
                        <Typography
                            data-testid="required"
                            variant="body3"
                            sx={{ color: palette.Red500 }}
                            color="Red500"
                        >
                            required
                        </Typography>
                    )}
                    {nullable === true && !required && (
                        <Typography
                            data-testid="nullable"
                            variant="body3"
                            sx={{ color: palette.Red500 }}
                            color="Red500"
                        >
                            nullable
                        </Typography>
                    )}
                </Box>
            )}

            {(description || (!['allOf', 'oneOf'].includes(type) && $enum !== undefined)) && (
                <Box
                    sx={{
                        '& ul': {
                            marginBottom: '0px',
                        },
                        '& ol': {
                            marginBottom: '0px',
                        },
                        '& li': {
                            padding: '0px',
                        },
                    }}
                >
                    {description && (
                        <Typography
                            data-testid="description"
                            variant="body3"
                            color="Grey900"
                            sx={{
                                color: palette.Grey900,
                                lineHeight: '160% !important',
                                '& p': {
                                    marginBottom: '0px',
                                },
                                '& > *:not(:first-child)': {
                                    marginTop: spacing(2),
                                },
                            }}
                        >
                            <Markdown>{description}</Markdown>
                        </Typography>
                    )}
                    {!['allOf', 'oneOf'].includes(type) && $enum !== undefined && (
                        <Box data-testid="enum" sx={{ marginTop: spacing(2) }}>
                            <Typography
                                variant="body3"
                                color="Grey900"
                                sx={{ color: palette.Grey900 }}
                            >
                                Enum:
                            </Typography>
                            <ul style={{ marginTop: spacing(1) }}>
                                {$enum.map(
                                    (item) =>
                                        // Due to response validation middleware, we have explicit null on some enums. We don't
                                        // need to present those here since nullable property info should be elsewhere.
                                        item && (
                                            <li key={item}>
                                                <Typography
                                                    variant="body3"
                                                    color="Grey900"
                                                    sx={{ color: palette.Grey900 }}
                                                >
                                                    {item}
                                                </Typography>
                                            </li>
                                        ),
                                )}
                            </ul>
                        </Box>
                    )}
                </Box>
            )}

            {type === 'array' && $ref && jsons!.length > 0 && !isSimpleJsonPropery(jsons![0]) && (
                <ExpandableList title="child attributes">
                    {expandArrayFirstChildRef(json, level)}
                </ExpandableList>
            )}
            {type === 'array' && !$ref && jsons!.length > 0 && jsons![0].type === 'allOf' && (
                <ExpandableList title="child attributes">
                    {expandAllOf(jsons![0], level).map((props, i) => (
                        <JsonProperty key={i} {...props} />
                    ))}
                </ExpandableList>
            )}
            {type === 'array' && !$ref && jsons!.length > 0 && jsons![0].type === 'oneOf' && (
                <ExpandableList title="child attributes">
                    {expandArrayFirstChildRef(json, level)}
                </ExpandableList>
            )}
            {type === 'array' && !$ref && jsons!.length > 0 && jsons![0].type === 'object' && (
                <ExpandableList title="child attributes">
                    {expandAllOf(json, level).map((props, i) => (
                        <JsonProperty key={i} {...props} />
                    ))}
                </ExpandableList>
            )}
            {type === 'object' && $ref && hasSingleObjectAsChild(json) && (
                <ExpandableList title="child attributes">
                    {(jsons![0] as ObjectJsonProperty).jsons.map((json, i) => (
                        <JsonProperty level={level + 1} key={i} json={json} />
                    ))}
                </ExpandableList>
            )}
            {type === 'object' && $ref && (jsons!.length > 1 || !hasSingleObjectAsChild(json)) && (
                <ExpandableList title="child attributes">
                    {jsons!.map((json, i) => (
                        <JsonProperty level={level + 1} key={i} json={json} />
                    ))}
                </ExpandableList>
            )}
            {type === 'object' && !$ref && jsons!.length > 0 && (
                <ExpandableList title="child attributes">
                    {jsons!.map((json, i) => (
                        <JsonProperty level={level + 1} key={i} json={json} />
                    ))}
                </ExpandableList>
            )}
            {type === 'allOf' && jsons!.length > 0 && (
                <ExpandableList title="child attributes">
                    {expandAllOf(json, level).map((props, i) => (
                        <JsonProperty key={i} {...props} />
                    ))}
                </ExpandableList>
            )}
            {type === 'oneOf' && jsons!.length > 0 && (
                <MutuallyExclusiveList defaultName={json.defaultName}>
                    {jsons!.map((json, i) => (
                        <MutuallyExclusiveListOption key={i} name={json.name}>
                            {json.type === 'allOf' &&
                                expandAllOf(json, level).map((props, i) => (
                                    <JsonProperty key={i} {...props} />
                                ))}
                            {json.type === 'object' &&
                                json.jsons.length > 0 &&
                                expandObject(json, level)}
                            {!['allOf', 'object'].includes(json.type) && (
                                <JsonProperty level={level + 1} json={json} />
                            )}
                        </MutuallyExclusiveListOption>
                    ))}
                </MutuallyExclusiveList>
            )}
        </StyledJsonProperty>
    )
}

export default JsonProperty
